import PropTypes from 'prop-types';
import Item from 'components/Item';
import PageLayout from 'components/PageLayout';
import toolsStructure from 'constants/tools-structure';

function ToolPage({ category }) {
  return (
    <PageLayout
      items={toolsStructure.find((item) => item.name === category)?.children.map(({
        name, subheader, description, link, status,
      }) => (
        <Item
          key={name}
          name={name}
          subheader={subheader}
          description={description}
          link={link}
          status={status}
        />
      ))}
      archiveItems={toolsStructure.find((item) => item.name === category).archive?.map(({
        name, subheader, description, link, status,
      }) => (
        <Item
          key={name}
          name={name}
          subheader={subheader}
          description={description}
          link={link}
          status={status}
        />
      ))}
    />
  );
}

ToolPage.propTypes = {
  category: PropTypes.string.isRequired,
};

export default ToolPage;

export default [
  {
    name: 'ERA R&D Tools',
    link: '/',
  },
  {
    name: 'WX',
    link: '/wx',
  },
  {
    name: 'RX',
    link: '/rx',
  },
  {
    name: 'Other',
    link: '/other',
  },
];

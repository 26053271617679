import PropTypes from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import pagesMetadata from 'constants/pages-metadata';
import CollapsibleSection from './CollapsibleSection';

function PageLayout({ children, items, archiveItems }) {
  return (
    <Box>
      <Box sx={{ marginBottom: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Title />
        </Box>
        <Divider />
      </Box>
      {items && (
      <Box sx={{
        display: 'flex', flexWrap: 'wrap', gap: 8, justifyContent: 'center',
      }}
      >
        {items}
      </Box>
      )}
      {archiveItems && (
      <CollapsibleSection title="Archive">
        {archiveItems}
      </CollapsibleSection>
      )}
      {children}
    </Box>
  );
}

PageLayout.propTypes = {
  archiveItems: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.node),
};

PageLayout.defaultProps = {
  archiveItems: null,
  children: null,
  items: null,
};

export default PageLayout;

function Title() {
  const location = useLocation();
  const pathParts = ['', ...location.pathname.split('/').filter((part) => part !== '')];

  return (
    <Breadcrumbs sx={{
      color: 'grey.500',
      fontWeight: 'lighter',
      '& .MuiBreadcrumbs-separator': {
        fontSize: 'h1.fontSize',
      },
    }}
    >
      {pathParts.map((part, i) => {
        const isCurrentPage = i === pathParts.length - 1;
        const path = part === '' ? '/' : pathParts.slice(0, i + 1).join('/');
        return (
          <Link key={part} component={RouterLink} to={path} underline="none">
            <Typography
              variant="h1"
              sx={{
                lineHeight: '1.5em',
              }}
              color={isCurrentPage ? 'grey.800' : 'grey.500'}
            >
              {pagesMetadata.find((pageMetadata) => pageMetadata.link === path).name}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CollapsibleSection({ title, children }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          marginTop: 4,
          marginBottom: 4,
        }}
        onClick={() => { setIsExpanded(!isExpanded); }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">{title}</Typography>
          <ExpandMoreIcon sx={{
            transform: isExpanded ? 'rotate(180deg)' : null,
            transition: (theme) => `transform 150ms ${theme.transitions.easing.easeInOut} 0ms`,
          }}
          />
        </Box>
        <Divider />
      </Box>
      <Collapse in={isExpanded}>
        <Box sx={{
          display: 'flex', flexWrap: 'wrap', gap: 8, justifyContent: 'center',
        }}
        >
          {children}
        </Box>
      </Collapse>
    </>
  );
}

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

CollapsibleSection.defaultProps = {
  children: null,
};

export default CollapsibleSection;

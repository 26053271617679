export default [
  {
    name: 'WX',
    subheader: 'Workplace Tools',
    children: [{
      name: 'WX.Floorplate',
      subheader: 'Floorplate Analysis Tool',
      description: '',
      link: 'https://wx-floorplate.superspace.tools/',
    },
    {
      name: 'WX.Layout',
      subheader: 'Layout Analysis Tool',
      description: '',
      status: 'Planned',
    },
    {
      name: 'WX.Network',
      subheader: 'Organization Analysis Tool',
      description: '',
      status: 'Planned',
    },
    {
      name: 'WX.Placement',
      subheader: 'Placement Tool',
      description: '',
      status: 'Planned',
    },
    {
      name: 'WX.Generate',
      subheader: 'Test-Fit Generation Tool',
      description: '',
      status: 'Planned',
    },
    {
      name: 'WX.Flow',
      subheader: 'Movement Simulation',
      description: '',
      status: 'Planned',
    },
    ],
    archive: [
      {
        name: 'WX.Stacking',
        subheader: 'Placement Tool',
        description: 'Place teams within a floorplate stack.',
        link: 'https://wx-stacking.superspace.tools/',
      },
      {
        name: 'WX.Organization',
        subheader: 'Network Analysis Tool',
        description: 'Visualize social/collaboration ties within an organization network.',
        link: 'https://wx-organization.superspace.tools/',
      },
      {
        name: 'WX.Observe',
        subheader: 'Time Utilization Analysis',
        description: 'Analyze and generate charts for time utilization data.',
        link: 'https://wx-observe.superspace.tools/',
      },
    ],
  },
  {
    name: 'RX',
    subheader: 'Retail Tools',
    children: [
      {
        name: 'RX.Spatial',
        subheader: 'Retail Analysis Tool',
        description: '',
        link: 'https://rx-spatial.superspace.tools/',
      },
    ],
  },
  {
    name: 'Other',
    subheader: 'Other Tools',
    children: [
      {
        name: 'Paint-By-Building',
        subheader: 'Heatmap Visualizer',
        description: 'Color regions by value.',
        link: 'https://paint-by-building.superspace.tools/',
      },
    ],
  },
];

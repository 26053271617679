import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function Item({
  name, subheader, description, link, status,
}) {
  return (
    <Card
      sx={{ width: 300, height: 300 }}
      elevation={link == null ? 0 : 2}
      variant={link == null ? 'outlined' : 'elevation'}
    >
      <CardActionArea
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
        href={link ?? '/'}
        disabled={link == null}
      >
        <CardHeader title={name} subheader={subheader} />
        <CardContent>
          <Typography>{description}</Typography>
        </CardContent>
        {link == null ? <Status status={status} /> : null}
      </CardActionArea>
    </Card>
  );
}

Item.propTypes = {
  description: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  subheader: PropTypes.string,
};

Item.defaultProps = {
  description: null,
  link: null,
  status: null,
  subheader: null,
};

export default Item;

function Status({ status }) {
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 2,
      backgroundColor: 'rgba(0,0,0,.1)',
    }}
    >
      <Typography variant="overline">{status}</Typography>
    </Box>
  );
}

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

import PageLayout from 'components/PageLayout';
import Item from 'components/Item';
import toolsStructure from 'constants/tools-structure';
import pagesMetadata from 'constants/pages-metadata';

function Home() {
  return (
    <PageLayout
      items={toolsStructure.map(({
        name, subheader, description,
      }) => (
        <Item
          key={name}
          name={name}
          subheader={subheader}
          description={description}
          link={pagesMetadata.find((pageMetadata) => pageMetadata.name === name).link}
        />
      ))}
    />
  );
}

export default Home;
